export class EarlytimeFlowDat {
  recordId: number;
  requestDate?: string | null;
  employeeId?: number | null;
  affiliationId?: number | null;
  shiftId?: string | null;
  earlytimeWorkDate?: string | null;
  earlytimeWorkStart?: string | null;
  earlytimeWorkBreak?: string | null;
  reason?: string | null;
  isEarlytime?: string | null;
  remarks?: string | null;
  startTimeStamp?: string;
  endTimeStamp?: string | null;
  latestApprovalLevel?: number | null;
  latestApprovalFlag?: string | null;
  latestApprovalDate?: string | null;
  constructor(props: EarlytimeFlowDat) {
    this.recordId = props.recordId;
    this.requestDate = props.requestDate;
    this.employeeId = props.employeeId;
    this.affiliationId = props.affiliationId;
    this.shiftId = props.shiftId;
    this.earlytimeWorkDate = props.earlytimeWorkDate;
    this.earlytimeWorkStart = props.earlytimeWorkStart;
    this.earlytimeWorkBreak = props.earlytimeWorkBreak;
    this.reason = props.reason;
    this.isEarlytime = props.isEarlytime;
    this.remarks = props.remarks;
    this.startTimeStamp = props.startTimeStamp;
    this.endTimeStamp = props.endTimeStamp;
    this.latestApprovalLevel = props.latestApprovalLevel;
    this.latestApprovalFlag = props.latestApprovalFlag;
    this.latestApprovalDate = props.latestApprovalDate;
  }
}
