
import Vue, { PropType } from "vue";
import { DateTime, Interval } from "luxon";
import PetitionSteppersDialog from "@/components/petition/steppers/PetitionSteppers.vue";
import {
  PetitionShiftSearch,
  PetitionShiftTableSearchResponse
} from "@/models/petition/PetitionResponse";

const startDate = 16;

const ApprovalState = {
  NONE: "0",
  DURING: "1",
  RESOLVE: "2",
  REJECT: "3"
} as const;

const activeBtnClass = "indigo--text text--darken-4 text-button text-center";
const disabledBtnClass = "disabled text-button text-center";

export default Vue.extend({
  name: "PetitionTable",
  components: { PetitionSteppersDialog },
  props: {
    date: {
      type: Object as PropType<DateTime>,
      required: true
    },
    adminView: {
      type: Boolean,
      required: false
    },
    workIncode: {
      type: Number || undefined,
      required: false
    },
    employees: {
      type: Array || undefined,
      required: false
    }
  },
  data() {
    return {
      user: 20,
      response: [] as PetitionShiftTableSearchResponse[],
      isActive: false,
      selectDate: DateTime.local(),
      shift: {} as PetitionShiftSearch,
      weekday: "",
      ApprovalState,
      defermentDay: undefined as DateTime | undefined,
      activeBtnClass,
      disabledBtnClass,
      employeeId: 0,
      isLoading: true
    };
  },
  computed: {
    month: {
      get(): DateTime[] {
        const start = this.date.minus({ month: 1 }).set({ day: startDate });
        const end = this.date.set({ day: startDate });
        const interval = Interval.fromDateTimes(start, end).splitBy({
          days: 1
        });
        /** @ts-ignore */
        return interval.flatMap(e => (e.start ? e.start : [e.mapEndpoints]));
      }
    }
  },
  watch: {
    async date() {
      await this.getShift();
    }
  },
  methods: {
    onClick(
      r: PetitionShiftSearch | undefined,
      m: DateTime,
      employeeId: number
    ) {
      if (r) {
        if (
          (r.approvalFlag === ApprovalState.DURING &&
            r.afdFlag !== ApprovalState.DURING &&
            r.ofdFlag !== ApprovalState.DURING &&
            r.efdFlag !== ApprovalState.DURING) ||
          (r.afdFlag === ApprovalState.DURING &&
            r.ofdFlag === ApprovalState.DURING &&
            r.efdFlag === ApprovalState.DURING)
        ) {
          this.$alert("warning", "承認中は申請できません。");
          return;
        }
        this.employeeId = employeeId;
        this.shift = r;
        this.selectDate = m;
        this.isActive = true;
        // 曜日情報を作成
        const week = ["", "月", "火", "水", "木", "金", "土", "日"];
        const w = m.weekday;
        console.log(m.weekday);
        this.weekday = "(" + week[w] + ")";
      }
    },
    dayClass(d: DateTime | null) {
      let result = "";
      switch (d?.weekdayShort) {
        case "土":
          result = "blue--text";
          break;
        case "日":
          result = "red--text";
          break;
        default:
      }
      return result;
    },
    tdClass(r: PetitionShiftSearch | undefined) {
      switch (r?.approvalFlag) {
        case ApprovalState.RESOLVE:
          //return "green lighten-4";
          return "success";
        case ApprovalState.REJECT:
          //return "red lighten-4";
          return "error";
        case ApprovalState.DURING:
          //return "blue lighten-4";
          return "primary";
        default:
          return "";
      }
    },
    getTargetShift(response: PetitionShiftTableSearchResponse, date: DateTime) {
      return response.shifts.find(e => {
        if (e.attendanceDate) {
          const attendanceDate = DateTime.fromMillis(e.attendanceDate);
          return attendanceDate.toISODate() === date.toISODate();
        }
      });
    },
    async getShift() {
      this.isLoading = true;
      const dateFrom = this.date
        .set({ day: startDate })
        .setZone("Asia/Tokyo")
        .minus({ month: 1 })
        .toJSDate();
      const dateTo = this.date
        .set({ day: startDate - 1 })
        .setZone("Asia/Tokyo")
        .toJSDate();
      const result = await this.$post<PetitionShiftTableSearchResponse[]>(
        this.Paths.petition.search.shiftTable,
        {
          dateFrom: dateFrom,
          dateTo: dateTo,
          affiliationId: this.$store.state.workIncode ?? this.workIncode,
          employees: this.employees
        }
      );
      if (result) {
        //ログイン者をテーブルの一番上に表示
        const incode = this.$store.state.user.incode as number | undefined;
        const ids = result.map(e => e.employeeId);

        if (incode) {
          const temp = result.splice(ids.indexOf(incode), 1);
          result.unshift(...temp);
        }
        this.response = result;
      }
      this.isLoading = false;
      console.log(result);
    },
    async getDefermentDay() {
      const result = await this.$get<string>(this.Paths.petition.defermentDay);
      if (result) this.defermentDay = DateTime.fromSQL(result);
    },
    isDisabled(employeeId: number, datetime: DateTime) {
      if (this.adminView == true) {
        return false;
      } else {
        const isClose = this.defermentDay ? this.defermentDay > datetime : true;
        const isLoginUser = employeeId !== this.$store.state.user.incode;

        const isDisabled = isLoginUser || isClose;

        return isDisabled;
      }
    }
  },
  async mounted() {
    console.warn("mount", this.adminView);
    await this.getDefermentDay();
    await this.getShift();
  }
});
