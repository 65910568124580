
import Vue from "vue";
import {
  VCard,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn,
  VIcon
} from "vuetify/lib";
import MonthPicker from "@/components/picker/MonthPicker.vue";
import PetitionTable from "@/components/petition/PetitionTable.vue";
import { DateTime } from "luxon";

const startDate = 16;
const today = DateTime.local();
const date =
  today.day >= startDate
    ? today.plus({ month: 1 }).startOf("month")
    : today.startOf("month");
const month = date.toFormat("yyyy-MM");

export default Vue.extend({
  name: "EarlyWorkApplAdmin",
  components: {
    VCard,
    VToolbar,
    //VToolbarTitle,
    //VSpacer,
    VBtn,
    VIcon,
    MonthPicker,
    PetitionTable
  },
  data() {
    return {
      month: month,
      title: "申請画面",
      adminView: true,
      isActive: false,
      shozokus: [],
      selectShozoku: { code: "", name: "" },
      employees: [],
      selectedEmployees: []
    };
  },
  computed: {
    date: {
      get(): DateTime {
        return DateTime.fromFormat(this.month, "yyyy-MM");
      }
    },
    workIncode: {
      get(): number {
        return Number(this.selectShozoku.code);
      }
    }
  },
  watch: {
    async workIncode() {
      await this.getShainByShozokuCode();
    },
    async selectedEmployees() {
      this.isActive = false;
    }
  },
  methods: {
    onRight() {
      this.month = this.date.plus({ month: 1 }).toFormat("yyyy-MM");
    },
    onLeft() {
      this.month = this.date.minus({ month: 1 }).toFormat("yyyy-MM");
    },
    async getShozoku() {
      const result = await this.$get<any>(this.Paths.shozokuFilter);
      if (result) {
        this.shozokus = result;
      }
    },
    showTable() {
      this.isActive = true;
    },
    async getShainByShozokuCode() {
      const param = { code1: this.workIncode };
      const encode = encodeURI(JSON.stringify(param));
      const result = await this.$get<any>(this.Paths.shain1, "query=" + encode);
      if (result) {
        this.employees = result;
      }
    }
  },
  async mounted() {
    await this.getShozoku();
  }
});
