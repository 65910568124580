const AlertMessages = {
  Attendance:
    "打刻漏れです。勤務時間変更申請画面で申請してください。\n※17日までに申請が無いと給与計算ができません。",
  Overtime:
    "時間超過です。 残業申請画面で申請してください。\n※17日までに申請が無い場合は、予定通りの勤務として処理します。",
  Absence: "遅刻しています。勤務時間変更申請画面で申請してください。",
  LeaveEarly: "早退しています。勤務時間変更申請画面で申請してください。",
  LegalHoliday:
    "公休出勤しています。正しい予定にシフトを変更してください。\n※17日までに申請が無い場合は、給与計算ができません。\n※公休とはシフト表にて出勤日とされていない日のこと ",
  Absent:
    "出勤日に打刻がありません。休暇申請画面で申請してください。\n※17日までに申請が無い場合は、欠勤として処理します。"
} as const;

const OvertimeHintMessages = {
  EndTime: "残業時間：超過後の退勤時刻を入力してください。",
  BreakTime:
    "休憩時間：超過した時間の中で「追加」された休憩時間を入力してください。"
};

const EarlytimeHintMessages = {
  workStart: "開始時間：早出する際の勤務開始時刻を入力してください。"
};

const Form = {
  Validate: "入力に誤りがあります。\n入力内容を確認してください。"
} as const;

const Success = {
  Regist: "登録に成功しました。",
  Update: "更新に成功しました。",
  Delete: "削除に成功しました。"
} as const;

const Fail = {
  Regist: "登録に失敗しました。",
  Update: "更新に失敗しました。",
  Delete: "削除に失敗しました。"
} as const;

export const Messages = {
  Alert: AlertMessages,
  OvertimeHint: OvertimeHintMessages,
  EarlytimeHint: EarlytimeHintMessages,
  Form,
  Success,
  Fail
} as const;
